.container {
    .inputNumber {
        width: 100%;
    }

    .select {
        width: 100%;
    }
    .input {
        width: 100%;
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
    }

    .tabs {
        margin-top: 24px;
    }
    .badge {
        margin-left: 5px;
        font-size: small;
    }
}
